import React, { Component } from 'react';

class ImgCard extends Component {

    render(){
        const {title, imgURL, description} = this.props
        return(
            <div className="imgCard">
                <div className="imgCard__container">
                    <img src={imgURL} alt=""/>
                </div>
                <div className="imgCard__text">
                    <div className="imgCard__text-title">
                        {title} ~
                    </div>
                    <div className="imgCard__text-description">
                        {description}
                    </div>
                </div>
            </div>
        )
    }
}

export default ImgCard;