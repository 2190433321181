import React from 'react';
import { Link } from 'react-router-dom';

import SignOutButton from '../SignOut';
import * as ROUTES from '../../constants/routes';
import { AuthUserContext } from '../Session';
import * as ROLES from '../../constants/roles';

const Navigation = () => (
  <AuthUserContext.Consumer>
    {authUser =>
      authUser ? (
        <NavigationAuth authUser={authUser} />
      ) : (
        <NavigationNonAuth />
      )
    }
  </AuthUserContext.Consumer>
);

const NavigationAuth = ({ authUser }) => (
    <div className="header">
      <div className="header__row">
        <Link className="header__row-link" to={ROUTES.HOME}>Home</Link>

        <Link className="header__row-link" to={ROUTES.ACCOUNT}>Account</Link>

        <Link className="header__row-link" to={ROUTES.ASSEMBLIES}>Assemblies</Link>
        
        {authUser.roles.includes(ROLES.ADMIN) && (         
          <Link className="header__row-link" to={ROUTES.ADMIN}>Admin</Link>
        )}
      </div>
    </div>
);

const NavigationNonAuth = () => (
  <div className="header">
    <div className="header__row">
        <Link className="header__row-link" to={ROUTES.LANDING}>Landing</Link>

        <Link className="header__row-link" to={ROUTES.PROJECTS}>Projects</Link>

        <Link className="header__row-link" to={ROUTES.CONTACT}>Contact</Link>

        <Link className="header__row-link" to={ROUTES.SIGN_IN}>Sign In</Link>
    </div>
  </div>

);

export default Navigation;



