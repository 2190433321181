const ULimages = [
  {
      ULtitle: '1_hour_ceiling_openweb',
      ULimgURL: require ('../assets/ULs/1hourceiling.png'),
      ULdescription: ('this is for a one hour ceiling assembly. 1 layer type C ceiling on 1/2" Rc6E. Resilient Channels - (Not Shown) — For Use With Item 7A - Formed from min 25 MSG galv steel installed perpendicular to trusses and spaced 16 in. OC. Channels secured to each truss with 1-5/8 in. long Type S bugle head steel screws. Channels overlapped 4 in. at splices. Two channels, spaced 6 in. OC, oriented opposite each gypsum panel end joint. Additional channels shall extend min 6 in. beyond each side edge of panel. Insulation, Item 5B is applied over the resilient channel/gypsum panel (Item 7A) ceiling membrane.7A. Gypsum Board* ­ (Not Shown) — For use with Items 5B and 6E. Nom 5/8 in. thick, 48 in. wide gypsum panels installed with long dimension perpendicular to resilient channels. Gypsum panels secured with 1 in. long Type S bugle head steel screws spaced 8 in. OC and located a min of 1/2 in. from side joints and 3 in. from the end joints. Finish Rating with this ceiling system is 20 min'),
  },
  {
      ULtitle: '1_hr_partywall_seperate_plates',
      ULimgURL: require ('../assets/ULs/1hrpartywall.png'),
      ULdescription: 'GA FILE NO. WP 3370 2bl stud 1hr wall GYPSUM WALLBOARD, WOOD STUDS One layer 5/8" type X gypsum wallboard or gypsum veneer base applied parallel or at right angles to each side of double row of 2 x 4 wood studs 16" o.c. on separate plates 1" apart with 6d coated nails, 1 7/8" long, 0.0915" shank, 1/4" heads, 7" o.c. Joints staggered 16" on opposite sides. Horizontal bracing required at mid-height. (LOAD- BEARING) GENERIC 1 HOUR FIRE 45 to 49 STC SOUND ',
  },
  {
      ULtitle: '1_hour_ceiling_openweb',
      ULimgURL: require ('../assets/ULs/1hrpartywallstaggered1plate2x4.png'),
      ULdescription: 'this is for a one hour ceiling assembly. 1 layer type C ceiling on 1/2" Rc',
  },
  {
      ULtitle: '1_hr_partywall_staggered_1_plate',
      ULimgURL: require ('../assets/ULs/1hrpartywallstaggered1plate.png'),
      ULdescription: '',
  },
  {
      ULtitle: 'STEEL COLUMN 1 HR WRAP',
      ULimgURL: require ('../assets/ULs/steelbeam1hr.png'),
      ULdescription:'STEEL COLUMN 1 HR WRAP- will be link TODO  -  http://productspec.ul.com/document.php?id=BXUV.X526   Base Layer: 5/8” type X or 1/2” type C gypsum board tied to steel column 1 hr. with 18-gauge wire 15” o.c. Face Layer:  gypsum board applied with laminating screws 8” on center or drywall adhesive.',
  },
  {
      ULtitle: '1_hour_ceiling_openweb',
      ULimgURL: require ('../assets/ULs/1hourceiling.png'),
      ULdescription: 'this is for a one hour ceiling assembly. 1 layer type C ceiling on 1/2" Rc',
  },
 
]

export default ULimages