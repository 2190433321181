import React,{Component} from 'react';

let startDate = new Date()
let endDate = new Date()

let elapsed = endDate - startDate

export default class TimeTrackerTwo extends Component{
    constructor(props){
        super(props)

        this.state ={
            startDate: [],
            endDate: [],
            elapsedTime: [],
        }
    }



    handleStartClick(){
        this.setState({
            startDate: new Date()
        })
    }

    handleStopClick(){
        this.setState({
            endDate: new Date()
        })
    }

    handleReturn(){
        this.setState({
            elapsedTime: this.state.endDate - this.state.startDate
        })
    }
    render(){
        console.log(this.state.startDate)
        console.log(this.state.endDate)
        console.log(this.state.elapsedTime / 1000)
        return(

            <div style={{zIndex: 10, color: 'white'}}>

                <div>
                    {this.state.startDate.toString()}
                    {this.state.elapsedTime.toString() / 1000}
                    <button onClick={this.handleStartClick.bind(this)}>Start</button>
                </div>
                <div>
                    <button onClick={this.handleStopClick.bind(this)}>Stop</button>
                </div>
                <div>
                    <button onClick={this.handleReturn.bind(this)}>total</button>
                </div>
            </div>
        )
    }
}