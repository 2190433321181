import React from 'react';
import SignOutButton from '../SignOut';

import { withAuthorization } from '../Session';
import TimeTracker from '../TimeTracker.js';
import TimeTrackerTwo from '../TimeTracker.js/TimeTrackerTwo';

const HomePage = () => (
  <div className="dashboard">
    <div className="dashboard__top">
        <div className="dashboard__logo">
            <img className="dashboard__logo-1" src={require("../../assets/whistleLogo.png")} alt="Logo"/>
        </div>
        <div className="dashboard__notifications">
            <div className="dashboard__notifications__box">
            </div>
        </div>
        <div className="dashboard__logout">
            <SignOutButton />
        </div>
    </div>
    <div className="dashboard__bottom">
        <div className="dashboard__training">
            <div className="dashboard__training-box">
            </div>
        </div>
        <div className="dashboard__tracker">
            <div className="dashboard__tracker-box">
                <TimeTracker />
            </div>
        </div>
        <div className="dashboard__manager">
            <div className="dashboard__manager-box">
            </div>
        </div>
    </div>
</div>
);

const condition = authUser => !!authUser;

export default withAuthorization(condition)(HomePage);