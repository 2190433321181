import React, { Component } from 'react';

class ULImgCard extends Component {

    render(){
        const {ULtitle, ULimgURL, ULdescription} = this.props
        return(
            <div className="ULimgCard">
                <div className="ULimgCard__container">
                    <img src={ULimgURL} alt=""/>
                </div>
                <div className="ULimgCard__text">
                    <div className="ULimgCard__text-title">
                        {ULtitle} ~
                    </div>
                    <div className="ULimgCard__text-description">
                        {ULdescription}
                    </div>
                </div>
            </div>
        )
    }
}

export default ULImgCard;