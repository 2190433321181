import React, {Component} from 'react';
import Modal from '../Modals/modal';

const formattedSeconds = (sec) =>
  Math.floor(sec / 60) +
    ':' +
  ('0' + sec % 60).slice(-2)

export default class TimeTracker extends React.Component {
  constructor(props) {
		super(props);
		// set initial state
		this.state = {
      secondsElapsed: 0, 
      Daily: [],
      lastClearedIncrementer: null,
			isModalOpen: false,
			isInnerModalOpen: false
    };
    // bind functions
    this.incrementer = null;
		this.closeModal = this.closeModal.bind(this);
		this.openModal = this.openModal.bind(this);
	}

	// close modal (set isModalOpen, true)
	closeModal() {
		this.setState({
			isModalOpen: false
		});
  }
  
	// open modal (set isModalOpen, false)
	openModal() {
		this.setState({
			isModalOpen: true
		});
  }
  
  handleStartClick() {
    this.incrementer = setInterval( () =>
      this.setState({
        secondsElapsed: this.state.secondsElapsed + 1
      })
    , 1000);
  }
  
  handleStopClick() {
    clearInterval(this.incrementer);
    this.setState({
      lastClearedIncrementer: this.incrementer
    });
  }
  
  handleResetClick() {
    clearInterval(this.incrementer);
    this.setState({
      secondsElapsed: 0,
      Daily: []
    });
  }
  
  handleSubmitClick() {
    this.setState({
      Daily: this.state.Daily.concat([this.state.secondsElapsed]),
      secondsElapsed: 0,
    })
    this.closeModal()
  }

	// render app
	render() {
		return (
      <div>
        <div className="stopwatch">
          <h1 className="stopwatch-timer">{formattedSeconds(this.state.secondsElapsed)}</h1>

          <div className="stopwatch-container">
            {(this.state.secondsElapsed === 0 ||
              this.incrementer === this.state.lastClearedIncrementer
              ? <button className="stopwatch-container-btn" onClick={this.handleStartClick.bind(this)}>clock in</button>
              : <button className="stopwatch-container-btn" onClick={this.handleStopClick.bind(this)}>clock out</button>
            )}
            
            
            {(this.state.secondsElapsed !== 0 &&
              this.incrementer === this.state.lastClearedIncrementer
              ? <button className="stopwatch-container-btn" onClick={this.openModal}>Submit</button>
              : null
            )}


            {(this.state.secondsElapsed !== 0 &&
              this.incrementer !== this.state.lastClearedIncrementer
              ? <button className="stopwatch-container-btn" onClick={this.handleResetClick.bind(this)}>reset</button>
              : null
            )}
          </div>
      

          <div className="stopwatch-days">
              <div className="stopwatch-days-title">Weeks Time</div>
            { this.state.Daily.map((day, i) =>
                <li className="stopwatch-day">{new Date().getMonth() + 1}/{new Date().getDate()} ~ {formattedSeconds(day)}</li>)
            }
              <div className="stopwatch-days-btn">
                <button>Submit</button>
              </div>
          </div>

        </div>
        
        
        <div style={mainStyle.app}>
          <Modal
            isModalOpen={this.state.isModalOpen}
            closeModal={this.closeModal}
            style={modalStyle}
          >
          <div>
            Are You Sure You Want To Submit?
          </div>

          <button
            style={{
              ...mainStyle.button,
              margin: 0,
              width: "auto",
              marginTop: 10
            }}
            onClick={this.handleSubmitClick.bind(this)}
          >
            Submit
          </button>

          <button
            style={{
              ...mainStyle.button,
              margin: 0,
              width: "auto",
              marginTop: 10
            }}
            onClick={this.closeModal}
          >
            Cancel
          </button>

          </Modal>
        </div>
      </div>
			
		);
  }
}

// overwrite style
const modalStyle = {
	overlay: {
		backgroundColor: "rgba(0, 0, 0,0.5)"
	}
};

const mainStyle = {
	app: {
		margin: "120px 0"
	},
	button: {
		backgroundColor: "#408cec",
		border: 0,
		padding: "12px 20px",
		color: "#fff",
		margin: "0 auto",
		width: 150,
		display: "block",
		borderRadius: 3
	}
};