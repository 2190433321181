import React from 'react';
import ULImgCard from '../ULimgCard/index';
import ULimages from '../../Data/assembliesData';

// import { withAuthorization } from '../Session';

const Assemblies = () => (
  <div className="Uls">
      <div className='ULimages'>
          {
              ULimages.map(image => {
                  return <ULImgCard {...image}/>
              })
          }
      </div>
  </div>
);
export default Assemblies;
// const condition = authUser => !!authUser;

// export default withAuthorization(condition)(Assemblies);