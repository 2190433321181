import React from 'react';
import ImgCard from '../imgCard/index';
import images from '../../Data/projectsData';

const Projects = () => (
  <div className="projects">
      <div className='images'>
          {
              images.map(image => {
                  return <ImgCard {...image}/>
              })
          }
      </div>
  </div>
);

export default Projects;