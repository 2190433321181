import React, { Component } from 'react';
import { Link } from 'react-router-dom';


export default class Contact extends Component {
  render() {
    return (
        <div className="contact">
            <div className="headerProjects"/>

            <div className="contact__form">
                <div className="form">
                    <div className="form-group">
                        <input type="text" className="form__input" placeholder="Full name" id="name" required/>
                        <label for="name" className="form__label">Full Name</label>
                    </div>
                    <div className="form-group">
                        <input type="text" className="form__input" placeholder="Email" id="name" required/>
                        <label for="email" className="form__label">Email Address</label>
                    </div>
                    <div className="form-group">
                        <input type="text" className="form__input form__input-content" placeholder="What can we do for you?" id="name" required/>
                        <label for="content" className="form__label">What can we do for you?</label>
                    </div>

                    <div className="form-group">
                        <button className="form__button">Submit</button>
                    </div>
                </div>
            </div>
        </div>
    );
  }
}