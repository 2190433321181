import ENCLAVE from '../assets/projects/ENCLAVE.jpg';

const images = [
    {
        title: 'Enclave',
        imgURL: require('../assets/projects/ENCLAVE.jpg'),
        description: 'Builder Wasatch DC Builders 1390 s 300 w. 3 70 plexes and 1 48 plex with club house'
    },
    {
        title: 'Encore', 
        imgURL: require('../assets/projects/Encore.jpg'),
        description: 'Builder Wasatch DC Builders 400 s 400 w 214 unit single building Podium',
    },
    {
        title: 'Parc On Center',
        imgURL: require('../assets/projects/ParcOnCenter.jpg'),
        description: 'Builder Wasatch DC Builders Orem 24-plexs 12-plex and 6-plexs',
    },
    {
        title: 'The Lofts',
        imgURL: require('../assets/projects/LOFTS.jpg'),
        description: 'Builder Wasatch DC Builders 7400 s. midvale 8 24 plexes',
    },
    {
        title: '600 Lofts',
        imgURL: require('../assets/projects/600Lofts.jpg'),
        description: 'Builder Wasatch DC Builders 200+ units single building podium',
    },
    {
        title: 'Kympton Square',
        imgURL: require('../assets/projects/LOFTS.jpg'),
        description: 'Builder Wasatch DC Builders 7800 s. midvale 90 unit podium senior home',
    },
    {
        title: 'Homewood Suites',
        imgURL: require('../assets/projects/Homewood.jpg'),
        description: 'Builder Wasatch DC Builders bangerter and I15 Hotel',
    },
    { 
        title: 'Savage Apartments',
        imgURL: require('../assets/projects/stock2.jpg'),
        description: 'Builder Wasatch DC Builders 6 24-plexs and 18 6-plexs',
    },
    {
        title: 'Garden Lofts',
        imgURL: require('../assets/projects/GardenLofts.png'),
        description: 'Builder Wasatch DC Builders down town parking in middle 5 story building wrap arround. 200+ units',
    },
    {
        title: 'Vista 9',
        imgURL: require('../assets/projects/Vista9.jpg'),
        description: 'Builder: Wasatch DC Builders Bangerter and I 15 200+ units wrap around parking 5 stories high',
    },
    {
        title: 'Parc West',
        imgURL: require('../assets/projects/PARC.jpg'),
        description: 'Builder: Wasatch DC Builders Bangerter and I 15 3 70-plexs and one 35 plex 5 stories high',
    },
    {
        title: 'San Tropez',
        imgURL: require('../assets/projects/SAN_TROPEZ.jpg'),
        description: 'Builder: Wasatch DC Builders',
    },
    {
        title: 'Zellerbach',
        imgURL: require('../assets/projects/Zeller.jpg'),
        description: 'Builder: Jacobsen 400 e 2200 s salt lake city. 200+ units wrap around parking 4 stories high',
    }
]

export default images