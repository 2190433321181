import React from 'react';

const Landing = () => (
  <div className="landing">
    <div className="landing__header">
      <div className="landing__header-intro">
        <div className="landing__header-intro--button">
          <a href="#" className="btn btn--red btn--animated">Discover More</a>
        </div>
      </div>
    </div>


    <section>
      <div className="aboutUs">
        <div className="aboutUs__header">
          <p>About Us</p>
        </div>

        <div className="aboutUs__content">
          <p>
              Whistle Construction Company is a leader in providing value-added construction services to our customers by creating a successful partnership with them throughout the construction process. Our pledge is to establish lasting relationships with our customers by exceeding their expectations and gaining their trust through exceptional performance by every member of the construction team. Whistle is an industry leader with decades of combined experience. We pride ourselves at being masters of craft and always keeping to our high standard of quality.
          </p>
        </div>

      </div>
    </section>

    <section className="section-services">
          <div className="u-center-text u-margin-bottom-big">
            <h2 className="heading-secondary">
                Main Services
            </h2>
          </div>

          <div className="row">
              <div className="col-1-of-3">
                  <div className="card">
                      <div className="card__side card__side--front">
                          <div className="card__picture card__picture--1">
                            &nbsp;
                          </div>
                          <h4 className="card__heading">
                              <span className="card__heading-span card__heading-span--1">
                                  DryWall
                              </span>
                          </h4>
                          <div className="card__details">
                              <ul>
                                  <li>Modern, nearly smooth textured walls</li>
                                  <li>1HR, 2HR, and 3HR rated walls and Pre Roc</li>
                                  <li>Drywall Content</li>
                                  <li>Drywall Content</li>
                              </ul>
                          </div>
                      </div>

                      <div className="card__side card__side--back card__side--back-1">
                          <div className="card__cta">
                              <div className="card__price-box">
                                  <p className="card__price-only">Contact Us</p>
                                  <p className="card__price-value">To Find Out More</p>
                              </div>
                              <a href="#" className="btn btn--white">Click Here!</a>
                          </div>
                      </div>
                  </div>
              </div>

              <div className="col-1-of-3">
                  <div className="card">
                      <div className="card__side card__side--front">
                          <div className="card__picture card__picture--2">
                            &nbsp;
                          </div>
                          <h4 className="card__heading">
                              <span className="card__heading-span card__heading-span--2">
                                  Framing
                              </span>
                          </h4>
                          <div className="card__details">
                              <ul>
                                  <li>Light Gauge To Heavy Gauge Walls</li>
                                  <li>Suspended Hard lids for Duct Covering as well as Custom Design</li>
                                  <li>Acoustical Ceilings, For Garages, Offices and Hallways.</li>
                                  <li>Sound Clips and RC1 Installation For Sound Barrier.</li>
                              </ul>
                          </div>
                      </div>

                      <div className="card__side card__side--back card__side--back-2">
                          <div className="card__cta">
                          <div className="card__price-box">
                                  <p className="card__price-only">Contact Us</p>
                                  <p className="card__price-value">To Find Out More</p>
                              </div>
                              <a href="#" className="btn btn--white">Click Here!</a>
                          </div>
                      </div>
                  </div>
              </div>

              <div className="col-1-of-3">
                  <div className="card">
                      <div className="card__side card__side--front">
                          <div className="card__picture card__picture--3">
                            &nbsp;
                          </div>
                          <h4 className="card__heading">
                              <span className="card__heading-span card__heading-span--3">
                                  Renovations
                              </span>
                          </h4>
                          <div className="card__details">
                              <ul>
                                  <li>Reframing of Walls</li>
                                  <li>Electrical/Mechanical/Plumbing</li>
                                  <li>Drywall Demolition</li>
                                  <li>Finishes(Drywall, Trim, Paint, Lighting, Flooring, Exterior Stucco)</li>
                              </ul>
                          </div>
                      </div>

                      <div className="card__side card__side--back card__side--back-3">
                          <div className="card__cta">
                          <div className="card__price-box">
                                  <p className="card__price-only">Contact Us</p>
                                  <p className="card__price-value">To Find Out More</p>
                              </div>
                              <a href="#" className="btn btn--white">Click Here!</a>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
        </section>

        <div className="footer">
        <div className="footer__row">
                <a className="footer__row-link" href="#">Projects</a>
                <a className="footer__row-link" href="#">Contact Us</a>
                <a className="footer__row-link" href="#">Login</a>
            </div>
        </div>
      </div> 
);

export default Landing;